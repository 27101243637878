import { startTransition } from 'react'

import { RemixBrowser } from '@remix-run/react'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { hydrateRoot } from 'react-dom/client'
import { initReactI18next } from 'react-i18next'
import { getInitialNamespaces } from 'remix-i18next/client'

import * as i18n from '@biogroup/i18n/config'

if (ENV.MODE === 'production') {
  import('./utils/monitoring.client.ts').then(({ init }) => init())
}

async function main() {
  // eslint-disable-next-line import/no-named-as-default-member
  await i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
      ...i18n,
      ns: getInitialNamespaces(),
      backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
      detection: {
        order: ['htmlTag'],
        caches: [],
      },
    })

  startTransition(() => {
    hydrateRoot(document, <RemixBrowser />)
  })
}

main().catch(error => console.error(error))
